import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import qs from 'qs';
import api from 'services/api';
import { useHttpClient } from 'shared/hooks/http-hook';
import { changeMoneyFormat } from 'utils/index';
import translations from 'constants/translations';
import enums from 'enums/index';
import { facebookColor, primaryColor } from 'assets/jss/material-dashboard-pro-react';
import styles from './ClientsBudgetList.styles';
import moment from 'moment';
const useStyles = makeStyles(styles);

export const useClientsBudgetList = () => {
  const { i18n } = useTranslation();
  const [clients, setClients] = useState([]);
  const [sendRequest, isLoading] = useHttpClient();
  const [title, setTitle] = useState('');
  const classes = useStyles();
  useEffect(() => {
    (async () => {
      try {
        const parsedSearchQuery = qs.parse(
          window.location.search?.split('?')[1] ? window.location.search?.split('?')[1] : 'search=',
        );
        const { chartType, monthlyDate, workLocation, title } = parsedSearchQuery;
        setTitle(title);

        await fetchClients({
          chartType,
          monthlyDate,
          workLocation,
        });
      } catch (err) {
        toast.error('An error has occurred');
      }
    })();
  }, [fetchClients, window.location.search]);

  const fetchClients = useCallback(
    async ({ chartType, monthlyDate, workLocation }) => {
      const response = await sendRequest(
        api.clientsResource.getClientForBudgetPlansStatistics({
          chartType,
          monthlyDate,
          workLocation,
        }),
      );
      setClients(response);
    },
    [setClients],
  );

  const columnsConfig = [
    {
      field: 'client',
      componentType: 'nameWithStatus',
      // component: data => <InvitationType type={parseInt(data)} />,
    },
    {
      field: 'phone',
    },
    {
      field: 'terminationDate',
      componentType: 'date',
      withoutTime: true,
    },
    {
      field: 'budgetHours',
    },
    {
      field: 'visitsHours',
    },
    {
      field: 'remainingBudget',
    },
    {
      field: 'notReachingTargetReasons',
      sorting: false,
      grouping: false,
    },
    {
      field: 'otherReasons',
      sorting: false,
      grouping: false,
    },
  ];
  const getCsvData = () =>
    clients?.map(client => ({
      ID: client?.id,
      'Client Name': client?.name,
      Phone: `${client.phoneNumber}`,
      'Termination Date': client.terminationDate ? moment(client.terminationDate).format('DD.MM.YYYY') : '-',
      'Budget Hours': changeMoneyFormat(client.budgetHours || 0),
      'Visits Hours': changeMoneyFormat(client.visitsHours || 0),
      'Remaining budget Hours': changeMoneyFormat(client.remainingBudget || 0),
      'Target Not Reached Reasons': client.notReachingTargetReasons
        .map(
          reason =>
            `${translations[i18n.language].targetNotReachedTypeObj[reason.targetNotReachedType]}- ${
              translations[i18n.language].notReachingTargetReasonObj[reason.notReachingTargetReason]
            }`,
        )
        .join('\n'),
      'Other Reasons': client.notReachingTargetReasons
        ?.map(
          reason =>
            reason.otherReason &&
            `${translations[i18n.language].targetNotReachedTypeObj[reason.targetNotReachedType]}- ${
              reason.otherReason
            }`,
        )
        .join('\n'),
    }));

  const tableDate = useMemo(
    () =>
      clients?.map(client => ({
        client: client,
        phone: client.phoneNumber,
        terminationDate: client.terminationDate,

        budgetHours: changeMoneyFormat(client.budgetHours || 0),
        visitsHours: changeMoneyFormat(client.visitsHours || 0),
        remainingBudget: changeMoneyFormat(client.remainingBudget || 0),
        notReachingTargetReasons: (
          <div key={`${JSON.stringify(client)}-attributes`} className={classes.tagsContainer}>
            {client.notReachingTargetReasons?.map((reason, index) => (
              <div
                key={index}
                className={classes.tag}
                style={{
                  backgroundColor:
                    reason.targetNotReachedType === enums.TargetNotReachedType.BUDGET ? facebookColor : primaryColor[3],
                }}
              >
                {`${translations[i18n.language].targetNotReachedTypeObj[reason.targetNotReachedType]} - 
        ${translations[i18n.language].notReachingTargetReasonObj[reason.notReachingTargetReason]}
        `}
              </div>
            ))}
          </div>
        ),
        otherReasons: (
          <div key={`${JSON.stringify(client)}-reasons`} className={classes.tagsContainer}>
            {client.notReachingTargetReasons?.map(
              (reason, index) =>
                reason.otherReason && (
                  <div
                    key={index}
                    className={classes.tag}
                    style={{
                      backgroundColor:
                        reason.targetNotReachedType === enums.TargetNotReachedType.BUDGET
                          ? facebookColor
                          : primaryColor[3],
                    }}
                  >
                    {reason.otherReason}
                  </div>
                ),
            )}
          </div>
        ),
      })),
    [clients],
  );
  return {
    isLoading,
    title,
    columnsConfig,
    tableDate,
    classes,
    getCsvData,
  };
};

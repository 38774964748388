import { useState, useCallback, useContext } from 'react';
import { toast } from 'react-toastify';
import { AuthContext } from '../context/auth-context';

export const useHttpClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const auth = useContext(AuthContext);

  const sendRequest = useCallback(async request => {
    setIsLoading(true);

    try {
      const response = await request;

      if (response == false) {
        auth.logout();
      }

      setIsLoading(false);

      return response?.data;
    } catch (err) {
      if (JSON.parse(JSON.stringify(err)).message === 'Network Error') {
        toast.error('Network Error Please Check Your Internet Connection');
        setIsLoading(false);
      }
      setError({
        message: err.response.data.message,
        ...(err.response?.data?.param ? { param: err.response.data.param } : {}),
      });
      setIsLoading(false);

      return Promise.reject(err);
    }
  }, []);

  const clearError = () => {
    setError(null);
  };

  const paramError = param => (error?.param && error.param === param ? error.message : '');

  return [sendRequest, isLoading, error, paramError, clearError];
};

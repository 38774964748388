import axios from 'axios';
import { saveAs } from 'file-saver';
import { BOUNDING_BOXES, GERMANY_BOUNDING_BOX, GOOGLE_MAP_SEARCH_BY_ADDRESS_URL } from 'constants/constants';
import { setAuthToken, createAxiosResponseInterceptor, axiosInstance } from './interceptor';

createAxiosResponseInterceptor();

const clearAuthToken = async () => {
  return setAuthToken('');
};

const login = authData => axiosInstance.post('auth/admin/login', authData);

const refreshToken = () => axiosInstance.post('auth/admin/refreshtoken');

const logout = () => axiosInstance.get('auth/admin/logout');

const komootInstance = axios.create({
  baseURL: 'https://photon.komoot.io/api',
});

const createFormData = data => {
  let formData = new FormData();
  Object.keys(data).forEach(key => {
    formData.append(
      key,
      typeof data[key] === 'object' && data[key]?.arrayBuffer === undefined ? JSON.stringify(data[key]) : data[key],
    );
  });
  return formData;
};

const createDataResource = (path, isMultipart = false) => ({
  getAll: (page = undefined, limit = undefined, search = undefined, filters = undefined, orderBy = undefined) => {
    return axiosInstance.get(path, {
      params: { page, limit, search, ...filters, orderBy },
    });
  },
  getOne: id => axiosInstance.get(`${path}/${id}`),
  post: data => {
    let response;
    if (isMultipart) {
      const formData = createFormData(data);
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      response = axiosInstance.post(path, formData, config);
    } else {
      response = axiosInstance.post(path, data);
    }
    return response;
  },
  patch: data => {
    let response;
    if (isMultipart) {
      const formData = createFormData(data);
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      response = axiosInstance.patch(path, formData, config);
    } else {
      response = axiosInstance.patch(path, data);
    }
    return response;
  },
  delete: id => axiosInstance.delete(`${path}/${id}`),
});

const insuranceCompaniesResource = createDataResource('insuranceCompanies');

const servicesResource = createDataResource('services');

const serviceCategoriesResource = createDataResource('serviceCategories');

const allergiesResource = createDataResource('allergies');

const restrictionsResource = createDataResource('restrictions');

const supportsResource = createDataResource('supports');
const nursingAdvisorsResource = createDataResource('nursingAdvisors');

const recommendedByClientsResource = createDataResource('recommendedByClients');

const recommendedByHelpersResource = createDataResource('recommendedByHelpers');

const preferredTimesResource = createDataResource('preferredTimes');

const accommodationSituationsResource = createDataResource('accommodationSituations');

const petsResource = createDataResource('pets');

const adminsResource = createDataResource('admins');

const helperRankingEvents = createDataResource('helperRankingEvents');

const documentsResource = {
  ...createDataResource('documents', true),
  deleteSignature: async payload => {
    return await axiosInstance.patch('documents/signature', payload);
  },
  createTermination: async data => {
    const formData = createFormData(data);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const response = await axiosInstance.post('documents/termination', formData, config);
    return response;
  },
  updateTermination: async data => {
    const formData = createFormData(data);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const response = await axiosInstance.patch('documents/termination', formData, config);
    return response;
  },
  getTerminations: async params => {
    const response = await axiosInstance.get('documents/termination', { params: { ...params } });
    return response;
  },
  deleteTermination: async id => {
    const response = await axiosInstance.delete(`documents/termination/${id}`);
    return response;
  },
  updateContract: async payload => {
    const response = await axiosInstance.patch(`documents/contract`, payload);
    return response;
  },
};

const helperTasksResource = {
  getLearningCertificateExpiresOrNotUploaded: () =>
    axiosInstance.get('helperTasks/learningCertificateExpiresOrNotUploaded'),
  getHelpersWithoutCriminalRecordsUploaded: () => axiosInstance.get('helperTasks/noCriminalRecord'),
  getContracts110NeedsRenewal: () => axiosInstance.get('helperTasks/renewContract110'),
  getEndingContractsWithinThreeMonths: () => axiosInstance.get('helperTasks/endingContracts'),
  getHelpersWithNoContact: () => axiosInstance.get('helperTasks/noContact'),
  getFirstContactProblemReports: params => axiosInstance.get('helperTasks/noContact', { params: { ...params } }),
  updateFirstContactProblemReport: payload => axiosInstance.patch('helperTasks/noContact', payload),
  getInactiveHelpers: () => axiosInstance.get('helperTasks/inactiveHelpers'),
  getHelperDocumentRequests: () => axiosInstance.get('helperTasks/documentRequests'),
  updateHelperDocumentRequests: (id, payload) => axiosInstance.patch(`helperDocumentRequests/${id}`, payload),
  getHourlyOverrun: () => axiosInstance.get('helperTasks/hourlyOverrun'),
  solveHourlyOverrun: helperId => axiosInstance.patch('helperTasks/hourlyOverrun/solve', { helperId }),
};

const clientTasksResource = {
  getClientTemporaryContracts: async () => {
    const response = axiosInstance.get('clientTasks/contract');
    return response;
  },
  getLaterReasons: async params => {
    const response = await axiosInstance.get(`clientTasks/contract/later`, { params });
    return response;
  },
  solveLaterReason: async payload => {
    const response = await axiosInstance.patch(`clientTasks/contract/later`, payload);
    return response;
  },
  getUnclearClients: () => axiosInstance.get('clientTasks/unclear'),
  getPendingInvitations: () => axiosInstance.get(`clientTasks/invitation/pending`),
  getLoopsWithoutMatch: () => axiosInstance.get(`clientTasks/invitation/loopsWithoutMatch`),
  getNewClients: params => axiosInstance.get(`clientTasks/newClients`, { params }),
  getOldClients: params => axiosInstance.get(`clientTasks/oldClients`, { params }),

  getServicePlanDowngradeClients: () => axiosInstance.get(`clientTasks/servicePlanDowngrade`),
  downgradeClientServicePlan: clientId => axiosInstance.patch(`clientTasks/${clientId}/servicePlan/downgrade`),
  ignoreDowngradingClientServicePlan: clientId => axiosInstance.patch(`clientTasks/${clientId}/servicePlan/ignore`),

  getExceedingBudgetClients: solved => axiosInstance.get(`clientTasks/exceedingBudget`, { params: { solved } }),
  changeIsExceedingBudgetSolved: data => axiosInstance.patch(`clientTasks/exceedingBudget/solve`, data),
};
const bugsResource = {
  ...createDataResource('bugs'),
  uploadAttachment: (bugId, file) => {
    let formData = new FormData();
    formData.append('bugId', bugId);
    formData.append('file', file);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const response = axiosInstance.post('bugs/upload', formData, config);
    return response;
  },
};
const letterResource = {
  ...createDataResource('letters'),
  getLetterDocument: params =>
    axiosInstance.get('letters/preview', {
      params: { ...params },
    }),
  uploadLetterOdt: ({ file }) => {
    let formData = new FormData();
    formData.append('file', file);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const response = axiosInstance.post('letters/upload', formData, config);
    return response;
  },
  sendLetter: data => axiosInstance.post('letters/send', data),
};

const statisticsResource = {
  getGeneralStatistics: params =>
    axiosInstance.get('statistics', {
      params: { ...params },
    }),
  getUsersIdsForSpecificBar: params =>
    axiosInstance.get('statistics/barIds', {
      params: { ...params },
    }),
  getClientsStatistics: params =>
    axiosInstance.get('statistics/clients', {
      params: { ...params },
    }),
  getHelpersStatistics: params =>
    axiosInstance.get('statistics/helpers', {
      params: { ...params },
    }),
  getKPIsStatistics: params =>
    axiosInstance.get('statistics/KPIs', {
      params: { ...params },
    }),
  getRevenueStatistics: params =>
    axiosInstance.get('statistics/revenue', {
      params: { ...params },
    }),
  getMarketingStatistics: params =>
    axiosInstance.get('statistics/marketing', {
      params: { ...params },
    }),
  getInvitationsStatistics: params =>
    axiosInstance.get('statistics/invitations', {
      params: { ...params },
    }),
  getTerminationStatistics: params =>
    axiosInstance.get('statistics/termination', {
      params: { ...params },
    }),
  getImpactStatistics: params =>
    axiosInstance.get('statistics/impact', {
      params: { ...params },
    }),
  downloadOtherReasons: async ({ params, fileName }) => {
    const response = await axiosInstance.get(`statistics/termination/download`, {
      params: params,
      responseType: 'blob',
    });
    const csvBlob = new Blob([response?.data], {
      type: 'text/csv',
    });
    saveAs(csvBlob, `${fileName}.csv`);
  },
  getAdminsStatistics: params =>
    axiosInstance.get('statistics/admins', {
      params: { ...params },
    }),
};

const clientsResource = {
  ...createDataResource('clients'),
  getInvitations: clientId => axiosInstance.get(`clients/${clientId}/helperInvitations`),
  getSignatures: params => axiosInstance.get(`clients/signature`, { params: { ...params } }),
  updateSpecificFields: data => axiosInstance.patch(`clients/specific`, data),
  updateClientPreferences: data => axiosInstance.patch(`clients/preferences`, data),
  generateContractOdtPdf: async (clientId, fileNum, fileName, isPdf) => {
    const response = await axiosInstance.get(`clients/${clientId}/contractOdt`, {
      params: { fileNum, pdf: isPdf },
      responseType: 'blob',
    });
    const wordBlob = new Blob([response?.data], {
      type: isPdf ? 'application/pdf' : 'application/vnd.oasis.opendocument.text',
    });
    saveAs(wordBlob, `${fileName}.${isPdf ? 'pdf' : 'odt'}`);
  },
  generateContractPreview: async (clientId, fileNum, setLoading) => {
    const response = await axiosInstance.get(`clients/${clientId}/contractOdt`, {
      params: { fileNum, pdf: true, preview: true },
    });
    setLoading(false);
    return response;
  },
  sendContract: async data => {
    const formData = createFormData(data);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const response = await axiosInstance.post(`clients/${data.clientId}/sendContract`, formData, config);
    return response;
  },
  getLetters: async (clientId, filters = {}) => {
    const response = await axiosInstance.get(`clients/${clientId}/letters`, { params: filters });
    return response;
  },
  updateLetter: async (clientId, letterId, data) => {
    const response = await axiosInstance.patch(`clients/${clientId}/letters/${letterId}`, data);
    return response;
  },
  updateShowPersonalInfo: (clientId, value) =>
    axiosInstance.patch('clients/personalinfo', {
      id: clientId,
      showPersonalInfo: value,
    }),
  getUsersForStatisticsBar: async data => {
    const response = await axiosInstance.post('clients/statisticsBar', data);
    return response;
  },

  terminateClientWithoutDocument: async data => {
    const response = await axiosInstance.patch(`clients/terminate`, data);
    return response;
  },
  cancelTermination: async data => {
    const response = await axiosInstance.patch(`clients/terminate/cancel`, data);
    return response;
  },
  getClientsForMap: params => axiosInstance.get(`clients/map`, { params }),
  getTerminationSituation: clientId => axiosInstance.get(`clients/${clientId}/terminationSituation`),

  markInvitationIssueAsSolved: clientId => axiosInstance.patch(`clients/${clientId}/markInvitationIssueAsSolved`),
  getClientForBudgetPlansStatistics: params => axiosInstance.get(`clients/statistics/budgetPlans`, { params }),
};
const helpersResource = {
  ...createDataResource('helpers'),
  getHelperInvoices: async params => axiosInstance.get(`helpers/invoices`, { params }),
  updateHelperInvoices: async data => axiosInstance.patch(`helpers/invoices`, data),
  unBillHelperInvoices: async data => axiosInstance.patch(`helpers/invoices/unBill`, data),
  getHelpersEmails: async params => axiosInstance.get(`helpers/emails`, { params }),
  getHelperClients: async params => axiosInstance.get(`helpers/${params.helperId}/clients`, { params }),
  generateHelperDocument: async ({ helperId, fileNum, fileName, fileType, setIsLoading }) => {
    const response = await axiosInstance.get(`helpers/${helperId}/document`, {
      params: { fileNum, fileType },
      responseType: 'blob',
    });
    const wordBlob = new Blob([response?.data], {
      type: response?.headers['content-type'],
    });
    setIsLoading(false);
    saveAs(wordBlob, `${fileName}.${fileType}`);
  },
  getClientAcceptedHelpers: async clientId =>
    axiosInstance.get(`invitations/acceptedHelpers`, { params: { clientId } }),

  getHelpersForPendingInvitationMap: async params => axiosInstance.get(`helpers/map`, { params }),

  getUsersForStatisticsBar: async data => {
    const response = await axiosInstance.post('helpers/statisticsBar', data);
    return response;
  },
};
const clientDraftsResource = createDataResource('clientDrafts');

const helperDraftsResource = {
  ...createDataResource('helperDrafts'),
  moveToHelpers: data => axiosInstance.patch(`helperDrafts/approve`, data),
};
const invitationsResource = {
  ...createDataResource('invitations'),
  updateStatus: data => axiosInstance.patch(`invitations/helper`, data),
  speedUpMatch: data => axiosInstance.post(`invitations/speedUp`, data),
  getSpeedUpsMatch: invitationId =>
    axiosInstance.get(`invitations/speedUp`, {
      params: {
        invitationId,
      },
    }),

  getInvitationCompletionSituation: params => {
    return axiosInstance.get(`invitations/completionSituation`, {
      params,
    });
  },
  getClientInvitations: clientId =>
    axiosInstance.get(`invitations/client`, {
      params: {
        clientId,
      },
    }),
  getClientActiveInvitations: clientId =>
    axiosInstance.get(`invitations/client/active`, {
      params: {
        clientId,
      },
    }),
  getHelperInvitations: ({ invitationId, clientId }) =>
    axiosInstance.get(`invitations/helper`, {
      params: {
        invitationId,
        clientId,
      },
    }),
  changeHelperInvitationsStatus: data => axiosInstance.patch(`invitations`, data),
  updateInvitation: data => axiosInstance.patch(`invitations/accepted/data`, data),
  startNewLoop: data => axiosInstance.patch(`invitations/newLoop`, data),
  startNow: data => axiosInstance.patch(`invitations/startNow`, data),
  updateSpecificFields: data => axiosInstance.patch(`invitations/specificFields`, data),
  updateServiceHours: data => axiosInstance.patch(`invitations/serviceHours`, data),
};

const helperInterviewsResource = {
  ...createDataResource('helperInterviews'),
};

const invoicesResource = {
  ...createDataResource('clientInvoices'),
  getClients: (
    page = undefined,
    limit = undefined,
    search = undefined,
    filters = undefined,
    filterWithOverallStatus = false,
  ) =>
    axiosInstance.get(`clientInvoices/clients`, {
      params: { filters, search, limit, page, filterWithOverallStatus },
    }),
  getClientInvoices: (filters = undefined, orderBy = undefined) =>
    axiosInstance.get(`clientInvoices/invoices`, {
      params: { ...filters, orderBy },
    }),
  calculate: (clientId, filters = undefined, recalculate = undefined) =>
    axiosInstance.get(`clientInvoices/${clientId}/calculate`, {
      params: { ...filters, recalculate },
    }),
  calculateAll: (filters = undefined) =>
    axiosInstance.get(`clientInvoices/calculateAll`, {
      params: { ...filters },
    }),
  downloadInvoice: async (id, filters, fileName, setLoading) => {
    const response = await axiosInstance.get(`clientInvoices/${id}`, {
      params: { ...filters },
      responseType: 'blob',
    });
    console.log(filters.isOdt);
    const wordBlob = new Blob([response?.data], {
      type: filters.isOdt ? 'application/vnd.oasis.opendocument.text' : 'application/pdf',
    });
    setLoading(false);
    saveAs(wordBlob, `${fileName}.${filters.isOdt ? 'odt' : 'pdf'}`);
  },
  downloadAllClientsInvoices: async (filters, setLoading) => {
    await axiosInstance.get(`clientInvoices/downloadAll`, {
      params: { ...filters },
    });
    setLoading(false);
  },
  downloadAllClientInvoices: async (clientId, filters, fileName, setLoading) => {
    const response = await axiosInstance.get(`clientInvoices/${clientId}/downloadAll`, {
      params: { ...filters },
      responseType: 'blob',
    });
    const pdfBlob = new Blob([response?.data], { type: 'application/pdf' });
    setLoading(false);
    saveAs(pdfBlob, `${fileName}.pdf`);
  },
  getClientStatistics: async params =>
    axiosInstance.get(`clientInvoices/statistics`, {
      params: { ...params },
    }),
  completeInvitationAndTerminate: data => axiosInstance.patch(`clientInvoices/completeInvitationAndTerminate`, data),
  getBudgets: ({ month, year, clientId }) => axiosInstance.get(`budgets/${clientId}/?month=${month}&year=${year}`),
  updateBudget: (clientId, invoice) => axiosInstance.patch(`budgets/${clientId}`, invoice),
  deleteInvoice: ({ month, year, clientId }) =>
    axiosInstance.delete(`clientInvoices/${clientId}?month=${month}&year=${year}`),
  getInvoicesBatches: async params => {
    const { data } = await axiosInstance.get(`clientInvoiceBatches`, {
      params: { ...params },
    });
    return { data };
  },
  sentInvoicesBatchesAt: async (invoiceBatchId, sentAt) => {
    const res = await axiosInstance.patch(`clientInvoiceBatches/${invoiceBatchId}`, { sentAt });

    return res;
  },
  sentInvoicesAt: async (invoiceId, sentAt) => {
    const res = await axiosInstance.patch(`clientInvoices/${invoiceId}`, { sentAt });
    return res;
  },
  uploadPaidInvoicesSheet: async fileFormData => {
    const response = await axiosInstance.patch(`clientInvoices/markAsPaid`, fileFormData);
    return response;
  },
  makeInvoiceAsPaid: async invoiceId => {
    const response = await axiosInstance.patch(`clientInvoices/${invoiceId}/markAsPaid`);
    return response;
  },
  remindClientInvoice: async invoiceId => {
    const response = await axiosInstance.patch(`clientInvoices/${invoiceId}/remindClient`);
    return response;
  },
  cancelInvoice: async invoiceId => {
    const response = await axiosInstance.patch(`clientInvoices/${invoiceId}/cancel`);
    return response;
  },
  getBudgetPlan: async ({ clientBudgetData, startDate }) => {
    const { data } = await axiosInstance.post(
      `clients/calculateClientBudgetPlan?startDate=${startDate}`,
      clientBudgetData,
    );
    return data;
  },
  updateClientServicePlan: async ({ clientId, servicePlan }) => {
    const { data } = await axiosInstance.patch(`clients/${clientId}/servicePlan`, { servicePlan });
    return data;
  },
};

const visitsResource = {
  ...createDataResource('visits'),
  declineVisits: data => axiosInstance.patch(`visits/decline`, data),
  postponeVisits: data => axiosInstance.patch(`visits/postpone`, data),
  updateMonthlyStatus: data => axiosInstance.patch(`visits/adminConfirm`, data),
};
const developersResource = {
  getMobileBugReports: params => axiosInstance.get(`mobileBugs`, { params }),
  updateMobileBugReportStatus: payload => axiosInstance.patch(`mobileBugs`, payload),

  getBackendBugs: params => axiosInstance.get(`backendBugs`, { params }),
  updateBackendBugStatus: payload => axiosInstance.patch(`backendBugs`, payload),
};
const helperTerminationResources = {
  getHelpersTerminationRequests: async params => axiosInstance.get(`helperTermination`, { params: { ...params } }),
  changeHelperTerminationRequestStatus: async data => axiosInstance.patch(`helperTermination/status`, data),
};
const helperVacationResources = {
  ...createDataResource('helperVacation'),
};
const monthlyVisitsResource = {
  ...createDataResource('monthlyVisits'),
  unConfirmMonthlyVisit: data => axiosInstance.patch(`monthlyVisits/unConfirm`, data),
  confirmUnconfirmedMonthlyVisits: data => axiosInstance.post(`monthlyVisits/confirmUnconfirmedMonthlyVisits`, data),
  confirmMonthlyVisit: data => axiosInstance.patch(`monthlyVisits/confirm`, data),
};
const clientDiscountsResource = {
  ...createDataResource('clientDiscounts'),
  updateAdminApproved: data => axiosInstance.patch(`clientDiscounts/adminApproved`, data),
  getClientDiscountsByReceivingClientId: receivingClientId => axiosInstance.get(`clientDiscounts/${receivingClientId}`),
};
const helperRewardsResource = {
  ...createDataResource('helperRewards'),
  getHelperRewardsByReceivingHelperId: receivingHelperId => axiosInstance.get(`helperRewards/${receivingHelperId}`),
};
const helperDocumentRequestsResource = {
  ...createDataResource('helperDocumentRequests', true),
};

const getLocation = (search, type, country, city, layer) => {
  let bbox = undefined;
  if (country && country === 'de') bbox = GERMANY_BOUNDING_BOX;
  if (city) {
    bbox = BOUNDING_BOXES[city];
  }

  let queryString =
    `q=${encodeURIComponent(search)}` +
    (type !== undefined ? `&osm_tag=${type === 'highway' ? '' : 'place:'}${encodeURIComponent(type)}` : '') +
    (bbox !== undefined ? `&bbox=${bbox}` : '');
  if (layer) queryString += `&layer=${layer}`;
  queryString = queryString + `&lang=de`;

  return komootInstance.get(`/?${queryString}`);
};

const getLocationByZipCode = async zipCode => {
  const url = GOOGLE_MAP_SEARCH_BY_ADDRESS_URL + zipCode + ', DEU';
  const response = await axios.get(url);
  const location = response?.data?.results[0]?.geometry?.location;
  return location;
};
export default {
  insuranceCompaniesResource,
  servicesResource,
  serviceCategoriesResource,
  allergiesResource,
  restrictionsResource,
  supportsResource,
  preferredTimesResource,
  accommodationSituationsResource,
  petsResource,
  adminsResource,
  clientsResource,
  helpersResource,
  invitationsResource,
  invoicesResource,
  visitsResource,
  documentsResource,
  helperTasksResource,
  bugsResource,
  clientDraftsResource,
  helperDraftsResource,
  recommendedByClientsResource,
  recommendedByHelpersResource,
  getLocation,
  getLocationByZipCode,
  login,
  refreshToken,
  setAuthToken,
  clearAuthToken,
  logout,
  statisticsResource,
  helperInterviewsResource,
  axiosInstance,
  komootInstance,
  monthlyVisitsResource,
  developersResource,
  helperTerminationResources,
  helperVacationResources,
  clientDiscountsResource,
  helperRewardsResource,
  letterResource,
  helperRankingEvents,
  clientTasksResource,
  nursingAdvisorsResource,
  helperDocumentRequestsResource,
};
